import React from 'react';

import { Box } from '@material-ui/core';

import BoxCapturePhoto from 'components/Box/BoxCapturePhotoWithBorderDashed';
import BoxCaptureWebCam from 'components/Box/BoxCapturePhotoWithWebCam';
import Button from 'components/Buttons/ButtonWrapperComponent';
import person_icon from 'assets/person_icon.png';
import CircularProgress from '@material-ui/core/CircularProgress';

const CapturePhotoComponent = ({
  contractData,
  setContractData,
  loadingContractData,
}) => {
  const webcamRef = React.useRef(null);
  const [activeCam, setActiveCam] = React.useState(false);

  const capturePhoto = React.useCallback(async () => {
    const { current }: any = webcamRef;
    setContractData({
      ...contractData,
      image: {
        base64Image: current?.getScreenshot(),
        fileName: current?.stream?.id,
        mimeType: current?.props?.screenshotFormat,
      },
    });
  }, [webcamRef]);

  const handleActiveCam = () => {
    setActiveCam(true);
  };

  return (
    <>
      {!activeCam && (
        <>
          <BoxCapturePhoto
            icon={person_icon}
            title="Por motivos de segurança é nescessário verificar a veracidade dos seus
          dados."
            description="Para isso será nescessário tirar uma foto, no formato de selfie, com
        seu rosto e documentos visíveis."
            mt={4}
          />
        </>
      )}
      {!!loadingContractData && <CircularProgress />}
      {!loadingContractData && !!activeCam && (
        <BoxCaptureWebCam
          url={contractData?.image?.base64Image}
          capturePhoto={capturePhoto}
          webcamRef={webcamRef}
        />
      )}

      <Box component="div" display="flex" justifyContent="center" mt={4}>
        {activeCam && (
          <Button
            label={
              contractData?.image?.base64Image ? 'Trocar Foto' : 'Tirar Foto'
            }
            onClick={capturePhoto}
            variant={
              contractData?.image?.base64Image ? 'outlined' : 'contained'
            }
            fullWidth={false}
          />
        )}
        {!activeCam && (
          <Button
            label="Tirar foto"
            onClick={handleActiveCam}
            variant="contained"
            fullWidth={false}
          />
        )}
      </Box>
    </>
  );
};

export default CapturePhotoComponent;
