import React from 'react';

const StepperContext = React.createContext({});

const StepperContextProvider: React.FC<any> = ({ children }) => {
  const [isActiveStep, setIsActiveStep] = React.useState(0);

  const nextStep = () => {
    setIsActiveStep(isActiveStep + 1);
  };

  const previousStep = () => {
    setIsActiveStep(isActiveStep - 1);
  };

  const finishSteps = () => {
    setIsActiveStep(3);
  };
  const value = { isActiveStep, nextStep, previousStep, finishSteps };
  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};

const withStepperContext = (Component: any) => (props: any) =>
  (
    <StepperContextProvider>
      <Component {...props} />
    </StepperContextProvider>
  );

const useStepper = () => React.useContext(StepperContext);

export {
  StepperContextProvider as default,
  StepperContext,
  useStepper,
  withStepperContext,
};
