import Webcam from 'react-webcam';

import { Box } from '@material-ui/core';

import { useTheme } from '@material-ui/core/';

import BoxCapturePhotoDefault from 'components/Box/BoxCapturePhotoDefault';

const videoConstraints = {
  width: 520,
  height: 437,
  facingMode: 'environment',
};
const videoConstraintsMobile = {
  width: 320,
  facingMode: { exact: 'user' },
};

const BoxCapturePhotoWithWebCamComponent = ({ webcamRef, url }: any) => {
  const onUserMedia = (e: any) => {
    console.log(e);
  };
  const matches = window.innerWidth <= 600;

  return (
    <BoxCapturePhotoDefault
      display="flex"
      justifyContent="center"
      height="437px"
      style={{ backgroundColor: '#424242' }}
    >
      <Box display="flex" justifyContent="center">
        {!url && (
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/png"
            videoConstraints={
              matches ? videoConstraintsMobile : videoConstraints
            }
            onUserMedia={onUserMedia}
          />
        )}

        {url && <img src={url} alt="Screenshot" />}
      </Box>
    </BoxCapturePhotoDefault>
  );
};

export default BoxCapturePhotoWithWebCamComponent;
