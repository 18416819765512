import React from 'react';

import { styles } from './Header.styles';

import { Box } from '@material-ui/core';

import { defaultTheme } from '@mobiauto/design-system-web';

const HeaderComponent = () => {
  return (
    <Box sx={styles.root} component="div">
      <Box component="h2" style={defaultTheme.typography.subtitleDesktop}>
        Proposta Digital
      </Box>
    </Box>
  );
};

export default HeaderComponent;
