import React from 'react';

import { colors } from 'configs/styles.config';
import { defaultTheme } from '@mobiauto/design-system-web';

import { Box, Typography } from '@material-ui/core';

import { propsTypes } from 'components/Box/BoxCapturePhoto.types';

const stylesBoxCaptureDefault = {
  root: {
    backgroundColor: colors.primary.lightWhite,
    borderRadius: '4px',
    height: '437px',
    color: colors.grey[400],
  },
};

const BoxCapturePhotoDefault: React.FC<propsTypes> = ({
  icon,
  title,
  description,
  children,
  style,
  ...rest
}) => {
  return (
    <Box sx={{ ...stylesBoxCaptureDefault.root, ...style }} {...rest}>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
        textAlign={{ xs: 'center', md: 'left' }}
        p={{ xs: 2, md: 0 }}
      >
        {children}
        {!children && icon && (
          <Box component="img" src={icon} alt="icon" mb={4} />
        )}
        {!children && title && (
          <Typography
            component="h3"
            sx={defaultTheme.typography.subtitle1}
            mb={2}
          >
            {title}
          </Typography>
        )}
        {!children && description && (
          <Typography component="p" sx={defaultTheme.typography.body1}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default BoxCapturePhotoDefault;
