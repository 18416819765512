import React from 'react';

import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';

// import { TextField } from '@mobiauto/design-system-web';

import Button from 'components/Buttons/';

import { baseUrl, apiVersion, ENDPOINTS } from 'configs/api.config';

import request from 'configs/request';

import { useContract } from 'contexts/ContractContext';

import { useModalValidateCPF } from 'contexts/ModalValidateCPFContext';

import MaskedInput from 'react-text-mask';
import { strip } from '@fnando/cpf';

const cnpjRegex = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
const cpfRegex = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const CPFCNPJMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  const buildMask = (rawValue) =>
    strip(rawValue).length > 11 ? cnpjRegex : cpfRegex;
  return <MaskedInput {...other} ref={inputRef} mask={buildMask} />;
};

const Formcomponent = () => {
  const { contractData, setContractData, setLoadingContractData }: any =
    useContract();
  const { id } = useParams();

  const { handleCloseValidateCpfModal, handleOpenValidateCpfModal }: any =
    useModalValidateCPF();

  const [inputValue, setInputValue] = React.useState('');

  const handleConfirm = async () => {
    const clearInputValue = inputValue.replace(/[^0-9 ]/g, '');
    const data = {
      cpfOrCnpj: clearInputValue,
      hash: id,
    };

    try {
      setLoadingContractData(true);
      handleCloseValidateCpfModal();

      const result: any = await request.post(
        `${baseUrl}/${apiVersion}/${ENDPOINTS.FIND}`,
        data,
        {}
      );
      setContractData({
        ...contractData,
        cpfOrCnpj: clearInputValue,
        hash: data.hash,
        data: result?.data,
        error: '',
      });

      if (result?.status !== 200) {
        const error = Object.values(result?.data?.errors || {})?.[0];
        handleOpenValidateCpfModal();
        setContractData({
          cpfOrCnpj: clearInputValue,
          error: error,
        });
      }
      setLoadingContractData(false);
    } catch (error) {
      setLoadingContractData(false);
      handleOpenValidateCpfModal();
      setContractData({
        cpfOrCnpj: clearInputValue,
        error: error,
      });
    }
  };
  const handleChange = ({ target }) => {
    setInputValue(target.value);
  };

  return (
    <Box component="div" display="flex" flexDirection="column">
      <Box component="div" mt={4}>
        <TextField
          label="CPF/CNPJ"
          onChange={handleChange}
          value={inputValue}
          error={!!contractData?.error}
          InputProps={{
            inputComponent: CPFCNPJMaskCustom,
            onChange: handleChange,
          }}
          fullWidth
        />
      </Box>
      <Box component="div" textAlign="center">
        <Box component="p" color="red" mt={1}>
          {contractData?.error}
        </Box>
      </Box>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        gap="16px"
        mt={2}
      >
        <Button variant="contained" label="Continuar" onClick={handleConfirm} />
      </Box>
    </Box>
  );
};

export default Formcomponent;
