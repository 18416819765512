type IVarName = {
  varName:
    | 'API_SEARCH_BASE_URL'
    | 'API_DIGITAL_BASE_URL'
    | 'API_DIGITAL_BASE_URL_PUBLIC'
    | 'API_ADMIN_BASE_URL'
    | 'API_IMAGES_BASE_URL'
    | 'API_SENTRY_DSN'
    | 'API_APM_SERVER'
    | 'API_DEALER_SITE_BASE_URL';
};

export const MS: any = {
  PAINEL_DIGITAL_BASE_URL: 'API_PAINEL_DIGITAL_BASE_URL',
  PAINEL_DIGITAL_BASE_URL_PUBLIC: 'API_PAINEL_DIGITAL_BASE_URL_PUBLIC',
  IMAGES_BASE_URL: 'API_IMAGES_BASE_URL',
};

export const ENDPOINTS = {
  FIND: 'proposal-digital/find',
  REPROVE: 'proposal-digital/reprove',
  APPROVE: 'proposal-digital/approve',
};

export function getEnvVarByName(varName: string) {
  return process.env[`REACT_APP_${varName}`] || '';
}

export const baseUrl = getEnvVarByName('API_PAINEL_DIGITAL_BASE_URL_PUBLIC');

export const baseImageUrl = getEnvVarByName('API_IMAGES_BASE_URL');

export const apiVersion = 'api/v1.0';
