import React from 'react';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/icons/CloseOutlined';
import Alert from '@material-ui/core/Alert';

type propsTypes = {
  open: boolean | undefined;
  setOpen: any;
  message: string;
  typeAlert: 'success' | 'info' | 'warning' | 'error';
};

const SnackbarComponent: React.FC<propsTypes> = ({
  open,
  setOpen,
  message,
  typeAlert,
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseAction = () => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton onClick={handleCloseAction} />
      </Box>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        action={action}
      >
        <Alert severity={typeAlert} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
