/* eslint-disable prettier/prettier */
import React from 'react';

import { Box } from '@material-ui/core';

import Stepper from 'components/Stepper/StepperDefault';
import VehicleData from 'components/OrderSteppers/VehicleData';
import Contract from 'components/OrderSteppers/Contract';
import Identification from 'components/OrderSteppers/Identification';
import ProposalAcceptance from 'components/OrderSteppers/ProposalAcceptance';
import FooterStepper from 'components/FooterStepper/';
import Button from 'components/Buttons/';

const OrderStepperComponent = ({
  isActiveStep,
  nextStep,
  previousStep,
  contractData,
  setContractData,
  loadingContractData,
  handleUploadImage,
  modalReprove,
}: any) => {
  const stepper: { [index: string]: any } = {
    0: <VehicleData />,
    1: <Contract />,
    2: <Identification />,
    3: (
      <ProposalAcceptance
        loadingContractData={loadingContractData}
        contractDataStatus={contractData?.data?.status}
      />
    ),
  };
  return (
    <>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
      >
        {contractData?.data?.status === 'WAITING' && (
          <Box component="div" mt={10}>
            <Stepper activeStep={isActiveStep} />
          </Box>
        )}

        <Box component="div" mt={5}>
          {stepper[isActiveStep]}
        </Box>
        {isActiveStep === 0 && (
          <FooterStepper
            rightContent={
              <Box component="div" display="flex" gap="10px">
                <Button
                  label="Reprovar"
                  onClick={() => modalReprove.handleOpenModal()}
                  variant="outlined"
                  red
                />
                <Button
                  label="Prosseguir"
                  onClick={nextStep}
                  variant="contained"
                />
              </Box>
            }
          />
        )}
        {isActiveStep === 1 && (
          <FooterStepper
            leftContent={
              <Button
                label="Voltar"
                onClick={previousStep}
                variant="outlined"
              />
            }
            rightContent={
              <Box
                component="div"
                display="flex"
                justifyContent="flex-end"
                width="100%"
              >
                <Box component="div" mr={0.5}>
                  <Button
                    label="Reprovar"
                    onClick={() => modalReprove.handleOpenModal()}
                    variant="outlined"
                    red
                  />
                </Box>
                <Box component="div">
                  <Button
                    label="Prosseguir"
                    onClick={nextStep}
                    variant="contained"
                  />
                </Box>
              </Box>
            }
          />
        )}
        {isActiveStep === 2 && (
          <FooterStepper
            leftContent={
              <Button
                label="Voltar"
                onClick={previousStep}
                variant="outlined"
                disabled={!!contractData?.image?.base64Image}
              />
            }
            rightContent={
              <Box
                component="div"
                display="flex"
                justifyContent="flex-end"
                width="100%"
              >
                <Box component="div" mr={0.5}>
                  <Button
                    label="Reprovar"
                    onClick={() => modalReprove.handleOpenModal()}
                    variant="outlined"
                    red
                  />
                </Box>
                <Box component="div">
                  <Button
                    label="Finalizar"
                    onClick={handleUploadImage}
                    disabled={!contractData?.image?.base64Image}
                    variant="contained"
                  />
                </Box>
              </Box>
            }
          />
        )}
      </Box>
    </>
  );
};

export default OrderStepperComponent;
