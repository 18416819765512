import { colors } from 'configs/styles.config';

export const styles = {
  textColorGreen: {
    color: `${colors.primary.main}`,
  },
  textColorBlack: {
    color: `${colors.black}`,
  },
  textColorGray: {
    color: `${colors.grey[400]}`,
  },
  textColorBlue: {
    color: `${colors.secondary.system}`,
    textDecoration: 'none',
  },
};
