import React from 'react';

const ModalContext = React.createContext({});

const ModalContextProvider: React.FC<any> = ({ children }) => {
  const [isValidateCpfModal, setIsValidateCpfModal] = React.useState(true);

  const handleOpenValidateCpfModal = () => {
    setIsValidateCpfModal(true);
  };
  const handleCloseValidateCpfModal = () => {
    setIsValidateCpfModal(false);
  };
  const value = {
    isValidateCpfModal,
    setIsValidateCpfModal,
    handleOpenValidateCpfModal,
    handleCloseValidateCpfModal,
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

const withModalContext = (Component: any) => (props: any) =>
  (
    <ModalContextProvider>
      <Component {...props} />
    </ModalContextProvider>
  );

const useModalValidateCPF = () => React.useContext(ModalContext);

export {
  ModalContextProvider as default,
  ModalContext,
  useModalValidateCPF,
  withModalContext,
};
