import React from 'react';

import CapturePhoto from 'components/CapturePhoto';

import { useContract } from 'contexts/ContractContext';

const IdentificationComponent = () => {
  const { contractData, setContractData, loadingContractData }: any =
    useContract();
  return (
    <CapturePhoto
      contractData={contractData}
      setContractData={setContractData}
      loadingContractData={loadingContractData}
    />
  );
};

export default IdentificationComponent;
