import React from 'react';

import { Button } from '@mobiauto/design-system-web';

type propsTypes = {
  variant: 'icon' | 'text' | 'contained' | 'outlined';
  fullWidth?: boolean;
  label: string;
  red?: boolean;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  icon?: React.ReactNode;
  loading?: boolean;
  onClick?: any;
};

const ButtonWrapperComponent: React.FC<propsTypes> = ({
  variant,
  fullWidth = false,
  label,
  red,
  disabled,
  startIcon,
  icon,
  onClick,
}) => {
  return (
    <Button
      variant={variant}
      fullWidth={fullWidth}
      label={label}
      red={red}
      disabled={disabled}
      startIcon={startIcon}
      icon={icon}
      onClick={onClick}
    />
  );
};

export default ButtonWrapperComponent;
