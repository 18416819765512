const grey = {
  100: '#fafafa',
  200: '#D3D3DA',
  300: '#BDBDBD',
  400: '#757575',
  500: '#808094',
  600: '#4F4F5E',
};
const primary = {
  main: '#009A8A',
  light: '#7DCAC2',
  lightWhite: '#fafafa',
};

const secondary = {
  system: '#4443BC',
  light: '#fdecea',
};

const danger = {
  system: '#F11D45',
  light: '#FEECEB',
  dark: '#621B16',
};

const warning = {
  system: '#F3E02E',
};

export const colors = {
  black: '#424242',
  white: '#FFFFFF',
  warning,
  danger,
  grey,
  primary,
  secondary,
};

export const DEFAULT_PADDING = 15;
