import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import Header from 'components/Header';
import Footer from 'components/Footer';
import OrderSteppers from 'components/OrderSteppers/';
import ModalValidateCPF from 'components/Modal/ModalValidateCPF';
import ModalReprove from 'components/Modal/ModalReprove';
import Snackbar from 'components/Snackbars';

import { useModalValidateCPF } from 'contexts/ModalValidateCPFContext';
import useModal from 'hooks/useModal';
import ImageService from 'services/image';

type propsTypes = {
  isActiveStep?: number;
  previousStep: any;
  nextStep: any;
  contractData: any;
  loadingContractData: any;
  snackBarOpen?: boolean;
  setSnackBarOpen?: any;
  snackBarMessage?: string;
  handleOpenSnackBar?: any;
  typeAlertSnackBar?: 'success' | 'info' | 'warning' | 'error';
};

const ProposalPage: React.FC<propsTypes> = ({
  isActiveStep,
  previousStep,
  nextStep,
  contractData,
  loadingContractData,
  snackBarOpen,
  setSnackBarOpen,
  snackBarMessage,
  typeAlertSnackBar,
}) => {
  const { isValidateCpfModal }: any = useModalValidateCPF();
  const modalReprove = useModal();
  const logoURL = ImageService.getPathForImageWithId({
    imageId: contractData?.data?.logoId,
    vcode: contractData?.data?.logoVcode,
  });

  return (
    <>
      <ModalValidateCPF />
      <ModalReprove modalReprove={modalReprove} />
      <Header />
      <Snackbar
        open={snackBarOpen}
        setOpen={setSnackBarOpen}
        message={snackBarMessage || ''}
        typeAlert={typeAlertSnackBar || 'success'}
      />
      {loadingContractData && (
        <Box component="div" display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}
      {!loadingContractData && !isValidateCpfModal && (
        <>
          <Container>
            <Box
              component="div"
              mt={4}
              display="flex"
              gap="16px"
              alignItems="center"
            >
              <Box
                component="div"
                width="34px"
                height="34px"
                border="1px solid #ccc"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="17px"
              >
                <Box component="img" src={logoURL} width="30px" />
              </Box>
              <Box
                component="h2"
                fontSize="18px"
                fontWeight="700"
                lineHeight="32px"
              >
                {contractData?.data?.dealerSocialName}
              </Box>
            </Box>
            <OrderSteppers
              isActiveStep={isActiveStep}
              previousStep={previousStep}
              nextStep={nextStep}
              modalReprove={modalReprove}
            />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default ProposalPage;
