import React from 'react';

import BoxCapturePhotoDefault from 'components/Box/BoxCapturePhotoDefault';

import { propsTypes } from 'components/Box/BoxCapturePhoto.types';

import { colors } from 'configs/styles.config';

const styles = {
  root: {
    border: `2px dashed ${colors.primary.light}`,
  },
};

const BoxCapturePhotoWithBorderDashedComponent: React.FC<propsTypes> = ({
  icon,
  title,
  description,
  ...rest
}) => {
  return (
    <BoxCapturePhotoDefault
      icon={icon}
      title={title}
      description={description}
      style={styles.root}
      {...rest}
    />
  );
};

export default BoxCapturePhotoWithBorderDashedComponent;
