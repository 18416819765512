export default function getImageTransformString(
  format,
  quality,
  width,
  height
) {
  const transformationString: string[] = [];
  if (format) {
    transformationString.push(`f_${format}`);
  }
  if (quality) {
    transformationString.push(`q_${quality}`);
  }
  if (width) {
    transformationString.push(`w_${width}`);
  }
  if (height) {
    transformationString.push(`h_${height}`);
  }
  return transformationString.join(',');
}
