import React from 'react';

import ProposalPage from './ProposalPage';

import StepperContextProvider, { useStepper } from 'contexts/StepperContext';

import ModalContextProvider from 'contexts/ModalValidateCPFContext';
import ContractProvider, { useContract } from 'contexts/ContractContext';
import SnackbarProvider, {
  useSnackbarContext,
  ISnackBarContextProps,
} from 'contexts/SnackbarContext';

const withContextsProviders = (Component: any) => (props: any) =>
  (
    <StepperContextProvider>
      <ModalContextProvider>
        <SnackbarProvider>
          <ContractProvider>
            <Component {...props} />
          </ContractProvider>
        </SnackbarProvider>
      </ModalContextProvider>
    </StepperContextProvider>
  );

const ProposalContainer = () => {
  const { isActiveStep, previousStep, nextStep }: any = useStepper();
  const { contractData, loadingContractData }: any = useContract();
  const {
    snackBarOpen,
    setSnackBarOpen,
    snackBarMessage,
    handleOpenSnackBar,
    typeAlertSnackBar,
  }: ISnackBarContextProps = useSnackbarContext();

  return (
    <>
      <ProposalPage
        isActiveStep={isActiveStep}
        previousStep={previousStep}
        nextStep={nextStep}
        contractData={contractData}
        loadingContractData={loadingContractData}
        snackBarOpen={snackBarOpen}
        setSnackBarOpen={setSnackBarOpen}
        handleOpenSnackBar={handleOpenSnackBar}
        snackBarMessage={snackBarMessage}
        typeAlertSnackBar={typeAlertSnackBar}
      />
    </>
  );
};

export default withContextsProviders(ProposalContainer);
