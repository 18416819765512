import React from 'react';

import { Modal, Box } from '@material-ui/core';

import { HighlightOff } from '@material-ui/icons';

import Button from 'components/Buttons';

import { colors } from 'configs/styles.config';

import { propsTypes } from '../Modal.types';

const ModalDefaultComponent: React.FC<propsTypes> = ({
  open,
  handleClose,
  mainContent,
  headerTitle,
  boxWidth = 420,
  boxHeight = 242,
}) => {
  const styles = {
    icon: {
      color: colors.grey[400],
    },
    title: {
      fontSize: '24px',
      fontWeight: '700',
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: { xs: '0 15px', md: '0px 0px' },
      }}
    >
      <Box
        component="div"
        sx={{ backgroundColor: colors.white, outline: 'none' }}
        width={{ xs: '100%', md: `${boxWidth}px` }}
        height={`${boxHeight}`}
        border={0}
        borderRadius="4px"
        p={4}
      >
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          textAlign="center"
        >
          <Box
            component="div"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <h3 style={styles.title}>{headerTitle}</h3>
          </Box>
        </Box>
        <Box component="div">{mainContent}</Box>
      </Box>
    </Modal>
  );
};

export default ModalDefaultComponent;
