import React from 'react';

import AlertDefault from 'components/Alert/AlertDefault';

import { colors } from 'configs/styles.config';
import styled from '@emotion/styled';

const styles = {
  root: {
    borderColor: colors.primary.light,
    color: colors.black,
  },
};

const AlertWithBorderColorComponent = ({ children, ...rest }: any) => {
  return (
    <AlertDefault style={styles.root} {...rest}>
      {children}
    </AlertDefault>
  );
};

export default AlertWithBorderColorComponent;
