import React from 'react';

import { Box } from '@material-ui/core';

import { colors } from 'configs/styles.config';

type propsTypes = {
  data?: Array<string>;
};

const styles = {
  root: {
    color: `${colors.grey[400]}`,
    backgroundColor: `${colors.grey[100]}`,
    borderRadius: 24,
  },
  liStyle: {
    backgroundColor: `${colors.grey[400]}`,
    width: 10,
    height: 10,
    marginRight: 5,
  },
};

const OptionalListComponent: React.FC<propsTypes> = ({ data }) => {
  return (
    <Box component="div">
      <Box component="div" sx={styles.root} maxWidth="min-content" p={1}>
        <span>Opcionais:</span>
      </Box>
      <Box
        component="ul"
        display="grid"
        gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr' }}
        ml={2}
      >
        {data?.map((item, index) => (
          <Box
            key={index}
            component="li"
            display="flex"
            alignItems="center"
            mt={1}
          >
            <div style={styles.liStyle} />
            {item}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OptionalListComponent;
