import { Box, Container } from '@material-ui/core';

import logo from 'assets/logo.png';

import { colors } from 'configs/styles.config';

const FooterComponent = () => {
  return (
    <Box
      component="footer"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ backgroundColor: colors.white }}
      p={3}
    >
      <Container>
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box component="div">
            {/* <span style={{ color: colors.black }}>
              Politicas de Privacidade:{' '}
            </span>
            <Link to="#" style={{ color: colors.primary.main }}>
              Acesse Aqui
            </Link> */}
          </Box>
          <Box component="div">
            <Box component="img" src={logo} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterComponent;
