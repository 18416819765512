import React from 'react';

import { Box } from '@material-ui/core';

import { defaultTheme } from '@mobiauto/design-system-web';
import { colors } from 'configs/styles.config';
import { useContract } from 'contexts/ContractContext';
import { base64toBlob } from 'utils/base64toBlob.util';
import Alert from 'components/Alert/AlertWrapper';

const BoxStatusInfoApproveComponent = () => {
  const { contractData }: any = useContract();

  const handlePrintContract = () => {
    try {
      //CHANGE_PDF_BASE64_TO_BLOB
      const blob = base64toBlob(
        contractData?.data?.contract,
        'application/pdf'
      );
      const url = window.URL.createObjectURL(blob);
      const link: any = document.createElement('a');
      link.href = url;
      window.open(link, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  const { dealerSocialName } = contractData?.data || {};

  return (
    <Alert alertType="border_green">
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        gap="16px"
      >
        <h3
          style={{
            ...defaultTheme.typography.subtitleDesktop,
            color: colors.primary.main,
          }}
        >
          Proposta Enviada!
        </h3>
        <p>
          O consentimento da sua pré-proposta já foi enviada, em breve
          entraremos em contato.
        </p>
        <p>
          Você pode acessar o contrato{' '}
          <a
            style={{ color: colors.secondary.system }}
            onClick={handlePrintContract}
          >
            clicando aqui
          </a>
        </p>
        <p>
          <strong>{dealerSocialName}</strong> agradece sua preferência
        </p>
      </Box>
    </Alert>
  );
};

export default BoxStatusInfoApproveComponent;
