import { colors } from 'configs/styles.config';

import { styled } from '@material-ui/core/styles/';

export const customConnectorStyles = {
  alternativeLabel: {
    top: 15,
    left: `calc(-50% + 10px)`,
    right: `calc(50% + 10px)`,
  },
  active: {
    '& $line': {
      backgroundColor: `${colors.primary.main}`,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: `${colors.primary.main}`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
};

export const customIconStyles = (props: any) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: `${colors.white}`,
    width: 32,
    height: 32,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: `${colors.primary.main}`,
  },
  completed: {
    backgroundColor: `${colors.primary.main}`,
  },
});

export const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: 'white',
  }),
  '& .QontoStepIcon-completedIcon-circle': {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: `${colors.primary.main}`,
  },
  '& .QontoStepIcon-completedIcon': {
    color: 'white',
    zIndex: 1,
    fontSize: 22,
    backgroundColor: `${colors.primary.main}`,
    fontWeight: 700,
  },
  '& .QontoStepIcon-circle': {
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5;',
    color: `${colors.black}`,
  },
}));

export const stepperActiveStyles = {
  backgroundColor: `${colors.primary.main}`,
  color: 'white',
};

export const stepperLabelStyles = {
  color: `${colors.black}`,
  fontWeight: 'bold',
};
