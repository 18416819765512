import React from 'react';

type ISnackbarProps = {
  open?: boolean;
  message?: string;
  setOpen?: any;
  handleOpen?: any;
  typeAlert?: string;
  setTypeAlert?: any;
};

const useSnackbar = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState<string>('');
  const [typeAlert, setTypeAlert] = React.useState<string>('');

  const handleOpen = (
    typeAlert: 'success' | 'info' | 'warning' | 'error',
    message: string
  ) => {
    setOpen(true);
    setTypeAlert(typeAlert);
    setMessage(message);
  };

  return { open, setOpen, typeAlert, setTypeAlert, message, handleOpen };
};

export default useSnackbar;
export type { ISnackbarProps };
