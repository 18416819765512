import React from 'react';

const SnackbarContext = React.createContext({});

import useSnackbar, { ISnackbarProps } from 'hooks/useSnackbar';

type ISnackBarContextProps = {
  snackBarOpen?: boolean;
  setSnackBarOpen?: any;
  snackBarMessage?: string;
  handleOpenSnackBar?: any;
  typeAlertSnackBar?: 'success' | 'info' | 'warning' | 'error';
};

const SnackbarContextProvider: React.FC<any> = ({ children }) => {
  const {
    open,
    setOpen,
    message,
    handleOpen,
    typeAlert,
    setTypeAlert,
  }: ISnackbarProps = useSnackbar();
  const value = {
    snackBarOpen: open,
    setSnackBarOpen: setOpen,
    snackBarMessage: message,
    handleOpenSnackBar: handleOpen,
    typeAlertSnackBar: typeAlert,
  };
  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

const withSnackbarContext = (Component: any) => (props: any) =>
  (
    <SnackbarContextProvider>
      <Component {...props} />
    </SnackbarContextProvider>
  );

const useSnackbarContext = () => React.useContext(SnackbarContext);

export {
  SnackbarContextProvider as default,
  SnackbarContext,
  useSnackbarContext,
  withSnackbarContext,
};

export type { ISnackBarContextProps };
