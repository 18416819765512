import { css } from '@emotion/react';

import { colors } from 'configs/styles.config';

export const styles = {
  root: {
    borderBottom: `2px solid ${colors.grey[300]}`,
    padding: '20px',
  },
  icon: {
    color: colors.grey[400],
  },
};
