import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { defaultTheme } from '@mobiauto/design-system-web';

import { styles } from './VehicleData.styles';

import Alert from 'components/Alert/AlertWrapper';
import OptionalList from 'components/OptionalList';

import { useContract } from 'contexts/ContractContext';

const VehicleDataComponent = () => {
  const {
    contractData: { data },
  }: any = useContract();
  const descriptions = data?.deal?.description?.split('|');

  return (
    <>
      <Alert alertType="border_green">
        <h3 style={defaultTheme.typography.subtitle1}>
          Olá, <strong style={styles.textColorGreen}>{data?.clientName}</strong>
          , confira abaixo a proposta que disponibilizamos para você.
        </h3>
      </Alert>
      <Box component="div" mt={2}>
        <h3
          style={{
            ...defaultTheme.typography.subtitle1,
            ...styles.textColorGreen,
          }}
        >
          DADOS DO VEÍCULO
        </h3>
      </Box>
      <Box component="div" mt={1}>
        <h2
          style={{
            ...defaultTheme.typography.subtitleDesktop,
            ...styles.textColorBlack,
          }}
        >
          {data?.deal?.name}
        </h2>
      </Box>
      <Box
        component="div"
        mt={2}
        display="flex"
        justifyContent="space-between"
        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Box component="div">
          <span style={styles.textColorGray}>Ano: </span>
          <span
            style={styles.textColorBlack}
          >{`${data?.deal?.productionYear}/${data?.deal?.modelYear}`}</span>
        </Box>
        {data?.deal?.color && (
          <Box component="div">
            <span style={styles.textColorGray}>Cor: </span>
            <span style={styles.textColorBlack}>{data?.deal?.color}</span>
          </Box>
        )}
        {data?.deal?.fuel && (
          <Box component="div">
            <span style={styles.textColorGray}>Combustível: </span>
            <span style={styles.textColorBlack}>{data?.deal?.fuel}</span>
          </Box>
        )}
        {data?.deal?.fuel && (
          <Box component="div">
            <span style={styles.textColorGray}>KM: </span>
            <span style={styles.textColorBlack}>
              {data?.deal?.km === 0 ? 'Zero' : data?.deal?.km}
            </span>
          </Box>
        )}
      </Box>
      {descriptions && (
        <Box component="div" mt={2}>
          <OptionalList data={descriptions} />
        </Box>
      )}

      <Box component="hr" mt={3} />
      <Box component="div" mt={2}>
        <h3
          style={{
            ...defaultTheme.typography.subtitle1,
            ...styles.textColorGreen,
          }}
        >
          CONDIÇÕES DE PAGAMENTO
        </h3>
      </Box>
      <Box
        component="div"
        mt={2}
        display="flex"
        justifyContent="space-between"
        sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
      >
        <Box component="div">
          <p style={styles.textColorGray}>Valor total: </p>
          <strong
            style={{
              ...defaultTheme.typography.subtitleDesktop,
              ...styles.textColorBlack,
            }}
          >
            {data?.totalPrice?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </strong>
        </Box>
        {data?.typeOfSale !== 'IN_CASH' && (
          <>
            <Box component="div">
              <p style={styles.textColorGray}>Valor entrada: </p>
              <strong
                style={{
                  ...defaultTheme.typography.subtitleDesktop,
                  ...styles.textColorBlack,
                }}
              >
                {data?.entryPrice?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </Box>
            <Box component="div">
              <p style={styles.textColorGray}>Valor financiado: </p>
              <strong
                style={{
                  ...defaultTheme.typography.subtitleDesktop,
                  ...styles.textColorBlack,
                }}
              >
                {data?.financingValue?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </Box>
            <Box component="div" mt={{ xs: 1, md: 0 }}>
              <p style={styles.textColorBlack}>Valor parcela: </p>
              <p>
                {data?.financingTerm}x de{' '}
                <strong
                  style={{
                    ...defaultTheme.typography.subtitleDesktop,
                    ...styles.textColorBlack,
                  }}
                >
                  {data?.financingInstallmentValue?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </strong>
              </p>
            </Box>
          </>
        )}
      </Box>
      <Box component="hr" mt={3} />
      <Box component="div" mt={3}>
        <h3
          style={{
            ...defaultTheme.typography.subtitle1,
            ...styles.textColorGreen,
          }}
        >
          LEI GERAL DE PROTEÇÃO DE DADOS
        </h3>
      </Box>
      <Alert mt={2}>
        Estou ciente que meus dados pessoas serão tratados em conformidade com a
        Política de Privacidade.
      </Alert>
    </>
  );
};

export default VehicleDataComponent;
