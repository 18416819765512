import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import BoxStatusInfoApprove from 'components/Box/BoxStatusInfoApprove';
import BoxStatusInfoReprove from 'components/Box/BoxStatusInfoReprove';
import BoxStatusInfoExpired from 'components/Box/BoxStatusInfoExpired';

import { useContract } from 'contexts/ContractContext';

const ProposalAcceptanceComponent = ({
  loadingContractData,
  contractDataStatus,
}: any) => {
  return (
    <>
      <Box component="div" height={{ xs: '0', md: '65vh' }}>
        {loadingContractData && (
          <Box component="div" display="flex" justifyContent="center" mt={4}>
            <CircularProgress />
          </Box>
        )}
        {contractDataStatus === 'APPROVED' && <BoxStatusInfoApprove />}
        {contractDataStatus === 'REPROVED' && <BoxStatusInfoReprove />}
        {contractDataStatus === 'CANCELED' && <BoxStatusInfoExpired />}
      </Box>
    </>
  );
};

export default ProposalAcceptanceComponent;
