import React from 'react';

import ModalDefault from 'components/Modal/ModalDefault/';

import FormComponent from 'components/Form/';

import { useModalValidateCPF } from 'contexts/ModalValidateCPFContext';

const ModalValidateCPFComponent = () => {
  const { isValidateCpfModal, handleCloseValidateCpfModal }: any =
    useModalValidateCPF();
  return (
    <ModalDefault
      open={isValidateCpfModal}
      headerTitle="Informe o CPF/CNPJ
      da proposta"
      mainContent={<FormComponent />}
    />
  );
};

export default ModalValidateCPFComponent;
