import React from 'react';
import { styled } from '@material-ui/core/styles/';
import {
  customConnectorStyles,
  customIconStyles,
  QontoStepIconRoot,
  stepperActiveStyles,
  stepperLabelStyles,
} from './Stepper.styles';

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepIconProps,
} from '@material-ui/core/';
import CheckCircle from '@material-ui/icons/CheckCircleOutline';

const ColorlibConnector = styled(StepConnector)(customConnectorStyles);

const QontoStepIcon = (props: StepIconProps) => {
  const icons: { [index: string]: any } = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
  };

  const { active, completed, className, tabIndex, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed || (active && icon === 4) ? (
        <div className="QontoStepIcon-completedIcon-circle">
          <CheckCircle className="QontoStepIcon-completedIcon" />
        </div>
      ) : (
        <div
          className="QontoStepIcon-circle"
          style={active ? stepperActiveStyles : {}}
        >
          {icons[String(props.icon)]}
        </div>
      )}
    </QontoStepIconRoot>
  );
};

type propsTypes = {
  activeStep?: number;
};

const StepperDefaultComponent: React.FC<propsTypes> = ({ activeStep }) => {
  const steps = React.useMemo(
    () => [
      'Dados do Veiculo',
      'Contrato',
      'Identificação ',
      'Aceite da Proposta',
    ],
    []
  );
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps?.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <span style={activeStep === index ? stepperLabelStyles : {}}>
                {label}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperDefaultComponent;
