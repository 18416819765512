import { Box } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudDownload from '@material-ui/icons/CloudDownload';

import Alert from 'components/Alert/AlertWrapper';
import Button from 'components/Buttons/';
import { useContract } from 'contexts/ContractContext';
import { base64toBlob } from 'utils/base64toBlob.util';

const ContractComponent = () => {
  const { contractData }: any = useContract();

  const handlePrintContract = () => {
    try {
      //CHANGE_PDF_BASE64_TO_BLOB
      const blob = base64toBlob(
        contractData?.data?.contract,
        'application/pdf'
      );
      const url = window.URL.createObjectURL(blob);
      const link: any = document.createElement('a');
      link.href = url;
      window.open(link, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadContract = () => {
    const blob = base64toBlob(contractData?.data?.contract, 'application/pdf');
    const url = window.URL.createObjectURL(blob);
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `contrato.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ flexDirection: { xs: 'column', md: 'row' } }}
      >
        <Alert>Segue impressão do pedido, que será assinado</Alert>

        <Button
          label="Download"
          onClick={handleDownloadContract}
          startIcon={<CloudDownload />}
          variant="text"
        />
      </Box>
      <Box
        component="div"
        height="65vh"
        mt={4}
        display="flex"
        justifyContent="center"
      >
        <Box
          component="div"
          width={{ xs: '100%', md: '50%' }}
          sx={{ backgroundColor: '#fafafa', border: '2px solid #BDBDBD' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            label="Visualizar PDF"
            onClick={handlePrintContract}
            startIcon={<PictureAsPdfIcon />}
            variant="contained"
          />
        </Box>
      </Box>
    </>
  );
};

export default ContractComponent;
