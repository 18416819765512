import { baseImageUrl } from 'configs/api.config';
import getImageTransformString from 'helpers/getImageTransformString';

const domain = baseImageUrl;

interface ImageData {
  imageId: number;
  vcode?: string;
}

export default class ImageService {
  static getPathForImageWithId(
    imageData: ImageData,
    config?: { format: string; quality: string; width: number; height: number }
  ): string {
    let transformStr = '';
    if (config) {
      const { format, quality, width, height } = config;
      transformStr = getImageTransformString(format, quality, width, height);
      return `${domain}/v1.0/${imageData?.imageId}/transform/${transformStr}${
        imageData?.vcode ? `?vcode=${imageData.vcode}` : ''
      }`;
    }
    return `${domain}/v1.0/${imageData.imageId}${
      imageData?.vcode ? `?vcode=${imageData.vcode}` : ''
    }`;
  }
}
