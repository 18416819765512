import React from 'react';

import { colors } from 'configs/styles.config';
import { defaultTheme } from '@mobiauto/design-system-web';

import Box from '@material-ui/core/Box';

import BoxDefault from '../BoxDefault';
import expired_icon from 'assets/task_expired.png';

const BoxStatusInfoExpiredComponent = () => {
  return (
    <BoxDefault p={4} mt={4} mb={4}>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="4px"
      >
        <Box component="img" src={expired_icon} />
        <h3 style={defaultTheme.typography.h3}>A proposta expirou</h3>
        <Box component="p" color={colors.black}>
          Contate a loja para fazer uma nova proposta
        </Box>
      </Box>
    </BoxDefault>
  );
};

export default BoxStatusInfoExpiredComponent;
