import React from 'react';

import Routes from './routes';
import { DSThemeProvider, defaultTheme } from '@mobiauto/design-system-web';
import { Global, css } from '@emotion/react';

const App = () => {
  return (
    <DSThemeProvider theme={defaultTheme}>
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
            height: 100vh;
            font-family: 'Poppins', sans-serif;
          }

          * {
            margin: 0;
            padding: 0;
          }

          a {
            text-decoration: none;
          }
        `}
      />
      <Routes />
    </DSThemeProvider>
  );
};

export default App;
