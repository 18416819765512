/* eslint-disable prettier/prettier */
import React from 'react';
import OrderStepperComponent from './OrderStepperComponent';
import {
  baseImageUrl,
  baseUrl,
  apiVersion,
  ENDPOINTS,
} from 'configs/api.config';
import request from 'configs/request';
import { useContract } from 'contexts/ContractContext';
import { useStepper } from 'contexts/StepperContext';
import {
  useSnackbarContext,
  ISnackBarContextProps,
} from 'contexts/SnackbarContext';
import { base64toBlob } from 'utils/base64toBlob.util';

const OrderStepperContainer = ({
  isActiveStep,
  nextStep,
  previousStep,
  modalReprove,
}) => {
  const {
    contractData,
    setContractData,
    setLoadingContractData,
    loadingContractData,
  }: any = useContract();
  const { handleOpenSnackBar }: ISnackBarContextProps = useSnackbarContext();
  const { finishSteps }: any = useStepper();

  const handleUploadImage = async () => {
    const blob = base64toBlob(contractData?.image?.base64Image, 'image/png');
    const file = new File([blob], `${contractData?.image?.fileName}.png`);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('imgOrigin', 'PROPOSAL_DIGITAL');
    try {
      setLoadingContractData(true);
      const result: any = await request.post(
        `${baseImageUrl}/v1.0?imgOrigin=PROPOSAL_DIGITAL`,
        formData,
        {}
      );

      if (result?.ok) {
        setContractData({ ...contractData, imageId: Number(result?.data?.id) });
        handleAproveProposal(Number(result?.data?.id));
        return;
      }

      if (result?.status !== 200) {
        const error = Object.values(result?.data?.errors || {})?.[0];
        handleOpenSnackBar('error', 'Ocorreu um Erro ao enviar a foto');
        setLoadingContractData(false);
      }
    } catch (error) {
      handleOpenSnackBar('error', error);
    }
  };

  const handleAproveProposal = async (imageId) => {
    const data = {
      cpfOrCnpj: contractData?.cpfOrCnpj,
      hash: contractData?.hash,
      imageId,
      location: `${contractData?.location?.latitude},${contractData?.location?.longitude}`,
    };

    try {
      setLoadingContractData(true);
      const aprove: any = await request.post(
        `${baseUrl}/${apiVersion}/${ENDPOINTS.APPROVE}`,
        data,
        {}
      );

      if (aprove?.ok) {
        const result = await request.post(
          `${baseUrl}/${apiVersion}/${ENDPOINTS.FIND}`,
          data,
          {}
        );
        setContractData({
          ...contractData,
          hash: data.hash,
          data: result?.data,
          error: '',
        });

        nextStep();
      }

      if (aprove.status !== 200) {
        const error = Object.values(aprove?.data?.errors || {})?.[0];
        handleOpenSnackBar('error', error);
      }
    } catch (error) {
      setContractData({
        ...contractData,
        error: error,
      });
    } finally {
      setLoadingContractData(false);
    }
  };

  const setPositions = ({ coords: { latitude, longitude } }) => {
    setContractData({
      ...contractData,
      location: { latitude: latitude, longitude: longitude, error: '' },
    });
  };

  const handlePosition = () => {
    window.navigator.geolocation
      ? window.navigator.geolocation.getCurrentPosition(setPositions)
      : setContractData({
          ...contractData,
          error: 'É necessário permitir a localização para continuar',
        });
  };

  const isWaiting = () => {
    if (contractData?.data?.status !== 'WAITING') {
      finishSteps();
    }
  };

  React.useEffect(() => {
    handlePosition();
  }, []);

  React.useEffect(() => {
    isWaiting();
  }, [contractData?.data?.status]);

  return (
    <OrderStepperComponent
      isActiveStep={isActiveStep}
      nextStep={nextStep}
      previousStep={previousStep}
      contractData={contractData}
      handleUploadImage={handleUploadImage}
      modalReprove={modalReprove}
      loadingContractData={loadingContractData}
    />
  );
};

export default OrderStepperContainer;
