import React from 'react';

import { Box, BoxProps } from '@material-ui/core';

import { colors } from 'configs/styles.config';

import styled from '@emotion/styled';

const AlertDefault = styled<any>(Box)`
  background: ${colors.grey[100]};
  border: 2px solid ${colors.grey[300]};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px 16px 16px;
  color: ${colors.grey[400]};
`;

type propsTypes = BoxProps & {
  styles?: any;
};

const AlertComponent: React.FC<propsTypes> = ({
  children,
  styles,
  ...rest
}: any) => {
  return (
    <AlertDefault style={{ ...styles }} {...rest}>
      {children}
    </AlertDefault>
  );
};

export default AlertComponent;
