import React from 'react';

import { BoxProps } from '@material-ui/core';

import AlertDefault from 'components/Alert/AlertDefault';
import AlertWithBorderColor from 'components/Alert/AlertWithBorderColor';

type propsTypes = BoxProps & {
  alertType?: 'default' | 'border_green';
};

const AlertWrapperComponent: React.FC<propsTypes> = ({
  alertType = 'default',
  children,
  ...rest
}) => {
  const ALERT_TYPE = {
    DEFAULT: 'default',
    BORDER_GREEN: 'border_green',
  };
  return (
    <>
      {alertType === ALERT_TYPE.DEFAULT && (
        <AlertDefault {...rest}>{children}</AlertDefault>
      )}
      {alertType === ALERT_TYPE.BORDER_GREEN && (
        <AlertWithBorderColor {...rest}>{children}</AlertWithBorderColor>
      )}
    </>
  );
};

export default AlertWrapperComponent;
