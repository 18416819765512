export const base64toBlob = (data: string, mimeType: string) => {
  const base64WithoutPrefix = data
    ?.substring(`data:${mimeType};base64,`.length)
    .toString();

  const bytes = window.atob(
    mimeType === 'application/pdf'
      ? data.replace(/\s/g, '')
      : base64WithoutPrefix
  );
  let length = bytes.length;
  const out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: mimeType });
};
