import React from 'react';

import { colors } from 'configs/styles.config';
import { defaultTheme } from '@mobiauto/design-system-web';

import Box from '@material-ui/core/Box';

import BoxDefault from '../BoxDefault';
import reprove_icon from 'assets/task_reprove.png';

const BoxStatusInfoReproveComponent = () => {
  return (
    <BoxDefault
      p={4}
      mt={4}
      mb={4}
      style={{
        backgroundColor: colors.danger.light,
        border: `1px solid ${colors.danger.dark}`,
      }}
    >
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="4px"
      >
        <Box component="img" src={reprove_icon} />
        <h3
          style={{ ...defaultTheme.typography.h3, color: colors.danger.system }}
        >
          Proposta Reprovada
        </h3>
        <Box component="p" color={colors.black}>
          Aguarde enquanto um vendendor entra em contato com você.
        </Box>
      </Box>
    </BoxDefault>
  );
};

export default BoxStatusInfoReproveComponent;
