import { BrowserRouter, Routes as Switch, Route } from 'react-router-dom';
import ROUTES from 'constants/routes.constants';

import Proposal from 'pages/Proposal';
import Reprove from 'pages/Reprove';

const RoutesPages = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.ROOT} element={<Proposal />} />
        <Route path={ROUTES.REPROVE} element={<Reprove />} />
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesPages;
