import React from 'react';

const ContractContext = React.createContext({});

type contractDeal = {
  color?: string;
  description?: string;
  fuel?: string;
  km?: number;
  modelYear?: number;
  name?: string;
  productYear?: number;
};

interface IData {
  contract: string;
  deal: contractDeal;
  entryPrice: number;
  financingInstallmentValue: number;
  financingTerm: number;
  financingValue: number;
  hash: string;
  proposalId: number;
  status: string;
  totalPrice: number;
}

interface ILocation {
  latitude: number;
  longitude: number;
  error: string;
}

export type contractProps = {
  cpfOrCnpj: string;
  hash: string;
  imageId: any;
  image?: string;
  location?: ILocation;
  data?: IData;
  error?: string;
};

const ContractContextProvider: React.FC<any> = ({ children }) => {
  const [contractData, setContractData] = React.useState<contractProps>({
    cpfOrCnpj: '',
    hash: '',
    imageId: null,
  });

  const [loadingContractData, setLoadingContractData] =
    React.useState<boolean>(false);

  const value = {
    contractData,
    setContractData,
    loadingContractData,
    setLoadingContractData,
  };
  return (
    <ContractContext.Provider value={value}>
      {children}
    </ContractContext.Provider>
  );
};

const withContractContext = (Component: any) => (props: any) =>
  (
    <ContractContextProvider>
      <Component {...props} />
    </ContractContextProvider>
  );

const useContract = () => React.useContext(ContractContext);

export {
  ContractContextProvider as default,
  ContractContext,
  useContract,
  withContractContext,
};
