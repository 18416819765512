interface IRoutes {
  ROOT: string;
  REPROVE: string;
}

const NAMESPACE = '/';

const ROUTES: IRoutes = {
  ROOT: `${NAMESPACE}:id`,
  REPROVE: `${NAMESPACE}reprove`,
};

export default ROUTES;
