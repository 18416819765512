import React from 'react';

import Box, { BoxProps } from '@material-ui/core/Box';

import { colors } from 'configs/styles.config';

type propsTypes = BoxProps & {
  style?: any;
};

const stylesBoxDefault = {
  root: {
    backgroundColor: colors.primary.lightWhite,
    borderRadius: '4px',
    border: `2px solid ${colors.grey[300]}`,
    color: colors.grey[400],
  },
};

const BoxDefaultComponent: React.FC<propsTypes> = ({
  children,
  style,
  ...rest
}) => {
  return (
    <Box sx={{ ...stylesBoxDefault.root, ...style }} {...rest}>
      {children}
    </Box>
  );
};

export default BoxDefaultComponent;
