import React from 'react';

import { Box } from '@material-ui/core';

type propsTypes = {
  leftContent?: React.ReactNode;
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

const FooterStepperComponent: React.FC<propsTypes> = ({
  leftContent,
  centerContent,
  rightContent,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="space-between"
      mb={2}
      mt={2}
    >
      <Box component="div">{leftContent}</Box>
      <Box component="div">{centerContent}</Box>
      <Box
        component="div"
        width="100%"
        display="flex"
        justifyContent={{ xs: 'center', md: 'flex-end' }}
      >
        {rightContent}
      </Box>
    </Box>
  );
};

export default FooterStepperComponent;
