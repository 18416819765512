import { create } from 'apisauce';
import NProgress from 'nprogress';

// define the api
const api = create({
  baseURL: '/',
  headers: { accept: '*/*' },
  // timeout: 30 * 1000, // 10 seconds
});

// #url - the relative path to the API
// #params - Object - query string variables
// #axiosConfig - Object - config passed along to the axios request
// #data - Object - the object jumping the wire

type IRequest = {
  url: string;
  params: any;
  axiosConfig?: any;
};

const request = {
  get: (url, params, axiosConfig) => {
    if (!url) {
      throw new Error('Missing url parameter');
    }
    NProgress.start();

    return api.get(url, params, axiosConfig).then((data) => {
      NProgress.done();

      return data;
    });
  },
  post: (url, data, axiosConfig) => {
    if (!url || !data) {
      throw new Error('Missing URL parameter and Missing DATA parameter ');
    }
    NProgress.start();
    return api.post(url, data, axiosConfig).then((data) => {
      NProgress.done();

      return data;
    });
  },
  put: (url, data, axiosConfig) => {
    if (!url || !data) {
      throw new Error('Missing URL parameter and Missing DATA parameter ');
    }
    NProgress.start();

    return api.put(url, data, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  delete: (url, params, axiosConfig) => {
    if (!url) {
      throw new Error('Missing url parameter');
    }
    NProgress.start();

    return api.delete(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  patch: (url, data, axiosConfig) => {
    if (!url || !data) {
      throw new Error('Missing URL parameter and Missing DATA parameter ');
    }
    NProgress.start();

    return api.patch(url, data, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  head: (url, params, axiosConfig) => {
    if (!url) {
      throw new Error('Missing url parameter');
    }
    NProgress.start();

    return api.head(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  link: (url, params, axiosConfig) => {
    if (!url) {
      throw new Error('Missing url parameter');
    }
    NProgress.start();

    return api.link(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
  unlink: (url, params, axiosConfig) => {
    if (!url) {
      throw new Error('Missing url parameter');
    }
    NProgress.start();

    return api.unlink(url, params, axiosConfig).then((data) => {
      NProgress.done();
      return data;
    });
  },
};
export { api };
export default request;
