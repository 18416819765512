import React from 'react';

import Box from '@material-ui/core/Box';

import Button from 'components/Buttons';

import ModalDefault from 'components/Modal/ModalDefault';

import { baseUrl, apiVersion, ENDPOINTS } from 'configs/api.config';

import request from 'configs/request';

import { useContract } from 'contexts/ContractContext';
import { useStepper } from 'contexts/StepperContext';
import {
  useSnackbarContext,
  ISnackBarContextProps,
} from 'contexts/SnackbarContext';

const ModalReproveComponent = ({ modalReprove }) => {
  const { contractData, setContractData, setLoadingContractData }: any =
    useContract();

  const { handleOpenSnackBar, snackBarOpen }: ISnackBarContextProps =
    useSnackbarContext();
  const { finishSteps }: any = useStepper();

  const handleReprove = async () => {
    const data = {
      cpfOrCnpj: contractData?.cpfOrCnpj,
      hash: contractData?.hash,
      location: `${contractData?.location.latitude},${contractData?.location.longitude}`,
    };

    try {
      setLoadingContractData(true);
      modalReprove?.handleCloseModal();
      const reprove: any = await request.post(
        `${baseUrl}/${apiVersion}/${ENDPOINTS.REPROVE}`,
        data,
        {}
      );

      if (reprove?.ok) {
        const result = await request.post(
          `${baseUrl}/${apiVersion}/${ENDPOINTS.FIND}`,
          data,
          {}
        );
        setContractData({
          ...contractData,
          hash: data.hash,
          data: result?.data,
          error: '',
        });

        finishSteps();
      }

      if (reprove?.status !== 200) {
        const error = Object.values(reprove?.data?.errors || {})?.[0];
        handleOpenSnackBar('error', error);
      }
    } catch (error) {
      handleOpenSnackBar('error', error || 'Ocorreu um Erro');
    } finally {
      setLoadingContractData(false);
    }
  };
  return (
    <ModalDefault
      open={modalReprove?.isOpen}
      handleClose={modalReprove?.handleCloseModal}
      headerTitle="Deseja Reprovar a proposta?"
      mainContent={
        <>
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            gap="16px"
            mt={2}
          >
            <Button
              variant="outlined"
              label="Cancelar"
              onClick={modalReprove?.handleCloseModal}
            />
            <Button
              variant="outlined"
              red
              label="Confirmar"
              onClick={handleReprove}
            />
          </Box>
        </>
      }
    />
  );
};

export default ModalReproveComponent;
